import React from 'react';

import './App.css';




function App() {
  return (
    <div className="App">
      <h1>
        <span className="nchar">N</span>irilia co.
      </h1>
      <p>Our website is coming soon</p>
      <p>please bear with us</p>
    </div>
  );
}

export default App;
